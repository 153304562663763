import { DEV_CONFIG } from "./config/dev.config";
import { TEST_CONFIG } from "./config/test.config";
import { PROD_CONFIG } from "./config/prod.config";
import { LOCAL_CONFIG } from "./config/local.config";
var config = null;

switch (window.location.host) {
  case "portal-dev.vitec-mv.com":
  case "localhost:1234":
    config = DEV_CONFIG;
    break;

  case "portal-test.vitec-mv.com":
    config = DEV_CONFIG;
    config.envIdentityPoolId = "eu-west-1:cbf0b709-083c-486f-88e4-339aefa0a590";
    break;

  case "portal.vitec-mv.com":
    config = DEV_CONFIG;
    config.envIdentityPoolId = "eu-west-1:d3f689f2-849d-4c1f-9c65-808d33833844";
    break;

  case "portal:1234":
    config = LOCAL_CONFIG;
    break;

  default: {
    throw new Error(`could not find env for '${window.location.host}'`);
  }
}

export { config };
