import React, { useEffect } from "react";
import Helmet from "react-helmet";
import "../../CSS/HomeStyle.css";

import { useTranslation } from "react-i18next";

import Authentication from "../components/Authentication";
import { Link } from "@reach/router";

/**
 * The landing page of the web portal. It shows links to all main pages for logged-in users, and the registration page for users who are not logged in.
 * @param {Object} param0 An object containing login information as well as navigational items.
 * @param {Boolean} param0.isLoggedIn A boolean that determines wether the landing page should be shown or if the registration page should be shown.
 * @param {Object[]} param0.menuItems An array of navigation items, with title, link, icon, and description.
 */
const Home = ({ isLoggedIn, menuItems }) => {
  const { t } = useTranslation();
  /**
   * Generates a grid of items that make navigation to the main areas of the website easy.
   *
   * @returns An array of React components with links to the respective pages.
   */
  function generateGrid() {
    const gridItems = menuItems.map((item, index) => (
      <Link
        to={item.link}
        key={item.title + index.toString()}
        className="home-nav-item"
      >
        <div className="title-tile">
          <img alt="" src={item.icon} />
          <span>{item.title}</span>
        </div>
        <p>{item.description}</p>
      </Link>
    ));
    if (gridItems.length % 2 !== 0) {
      gridItems.push(
        <Link
          to={"/"}
          disabled={true}
          key={"placeholder item"}
          className="home-nav-item"
          id="placeholder-button"
        ></Link>
      );
    }
    return gridItems;
  }

  return (
    <div>
      <Helmet>
        <title>{"Vitec MV - " + t("landingPageTitle")}</title>
      </Helmet>
      {isLoggedIn !== true ? (
        <Authentication />
      ) : (
        <div>
          <h1>{t("landingPageTitle")}</h1>
          <div className="home-navigation-container">{generateGrid()}</div>
        </div>
      )}
    </div>
  );
};

export default Home;
