import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { userManagementService } from "../api/service";
import { serviceConfigurator } from "../service/ServiceConfigurator";
import AlertBox from "./AlertBox";
import Loader from "../components/Loader";
// CSS
import "../../CSS/PersonalInformationFormStyle.css";
// Images
import emailLogo from "../img/email.svg";
import passWordLogo from "../img/password.svg";
import nameLogo from "../img/profile.svg";
import addressLogo from "../img/street.svg";
import city from "../img/city.svg";
import country from "../img/country.svg";
import phone from "../img/phone.svg";

/**
 * Handling the user's personal information in a form.
 * @param {object} param0 - An object bundling all necessary information.
 * @param {boolean} param0.isRegistration - Determines whether the form is used for existing users or for registration of new users.
 * @param {object} param0.userInfo - The information that should be shown in the form.
 * @param {string} param0.userInfo.email - The user's e-mail address.userInfo
 * @param {number} param0.userInfo.password - The user's password.
 * @param {Text} param0.userInfo.givenName - The user's first name.
 * @param {Text} param0.userInfo.familyName - The user's last name.
 * @param {Text} param0.userInfo.address - The user's street name and house number.
 * @param {Text} param0.userInfo.postcode - The user's post code.
 * @param {Text} param0.userInfo.town - The town in which the user lives.
 * @param {Text} param0.userInfo.country - The country in which the user lives.
 * @param {Text} param0.userInfo.phonenumber - The country in which the user lives.
 * @param {boolean} param0.userInfo.consent - The users consent to storing data
 * @param {boolean} param0.userInfo.newsletter - The users state of newsletter subscription
 * @param {Function} param0.onCreateUser - The function that should be called when the user submits the form for registration.
 * @param {Function} param0.onUpdateUser - The function that should be called when the user submits the form for updating.
 * @param {Boolean} param0.forceDisableEditMode - An optional boolean to force disable the edit mode.
 */
const PersonalInformationForm = ({
  isRegistration,
  userInfo,
  onCreateUser,
  onUpdateUser,
  forceDisableEditMode = false
}) => {
  const { t } = useTranslation();
  const [isEditingDisabled, updateEditingState] = useState(
    !isRegistration || forceDisableEditMode
  );

  const [consentState, setConsentState] = useState(false);
  const [newsletterState, setNewsletterState] = useState(true);

  function onUpdateEditingState(newState) {
    updateEditingState(forceDisableEditMode || newState);
  }

  const [userInfoState, setUserInfoState] = useState(userInfo);

  const [disableEditMode, setDisableEditMode] = useState(forceDisableEditMode);

  const [isValidated, setIsValidated] = useState(false);

  const [
    isFailedRegistrationAlertVisible,
    updateFailedRegistrationAlertVisibility
  ] = useState(false);

  const [isLoaderVisible, updateLoaderVisibility] = useState(false);

  // Dictionary containing the possible countries to show in list.
  var countryCodes = {};
  countryCodes["DK"] = "Danmark";
  countryCodes["NO"] = "Norge";
  countryCodes["SV"] = "Sverige";

  function createUserClicked() {
    userInfoState.consent = consentState;
    userInfoState.newsletter = newsletterState;

    updateLoaderVisibility(true);
    serviceConfigurator();
    userManagementService
      .get()
      .isUsernameUnique(btoa(userInfoState.username))
      .then(response => {
        if (response.status === 200) {
          updateLoaderVisibility(false);
          onCreateUser(userInfoState);
          setDisableEditMode(false);
          setConsentState(false);
          setNewsletterState(true);
          setUserInfoState(userInfo);
        }
      })
      .catch(error => {
        updateLoaderVisibility(false);
        if (error.status === 409) {
          updateFailedRegistrationAlertVisibility(true);
        }
      });
  }

  function updateUserClicked() {
    onUpdateUser(userInfoState);
    onUpdateEditingState(!isEditingDisabled);
  }

  useEffect(() => {
    if (isRegistration) {
      var regExMail = /\S+@\S+\.\S+/;
      var regExPhone = /[0-9]/;
      if ( userInfoState.username.length > 0 &&
          regExMail.test(userInfoState.username) &&
          userInfoState.password.length > 0 &&
          userInfoState.givenName.length > 0 &&
          userInfoState.surName.length > 0 &&
          userInfoState.address.length > 0 &&
          userInfoState.postCode.length >= 4 &&
          userInfoState.city.length > 0 &&
          userInfoState.phonenumber.length >= 8 &&
          regExPhone.test(userInfoState.phonenumber) &&
          userInfoState.countryRegionCode.length > 0) {

        setIsValidated(true);
      } else {
        setIsValidated(false);
      }
    }
  }, [userInfoState]);

  useEffect(() => {
    setUserInfoState(userInfo);
  }, [userInfo]);
  return (
    <form
      className="personal-information-form"
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <Loader isVisible={isLoaderVisible} />
      {isFailedRegistrationAlertVisible ? (
        <AlertBox
          style="NEUTRAL"
          title={t("registrationFailedTitle")}
          message={t("UsernameTaken")}
          defaultButtonAction={() => {
            updateFailedRegistrationAlertVisibility(false);
          }}
        />
      ) : null}
      <label className="personal-info-item">
        <p>
          <img src={emailLogo} alt="" /> {t("eMail")}
        </p>
        <input
          className="input"
          type="email"
          disabled={!isRegistration || disableEditMode}
          value={userInfoState.username}
          onChange={e =>
            setUserInfoState({ ...userInfoState, username: e.target.value })
          }
          required
        />
      </label>

      {isEditingDisabled ? null : (
        <label className="personal-info-item">
          <p>
            <img src={passWordLogo} alt="" />
            {isRegistration ? t("password") : t("newPassword")}
          </p>
          <input
            type="password"
            className="input"
            disabled={isEditingDisabled}
            hidden={isEditingDisabled}
            value={userInfoState.password}
            onChange={e =>
              setUserInfoState({ ...userInfoState, password: e.target.value })
            }
          />
        </label>
      )}

      <div className="two-part-label">
        <label className="personal-info-item">
          <p>
            <img src={nameLogo} alt="" />
            {t("givenName")}
          </p>
          <input
            type="text"
            className="input"
            disabled={isEditingDisabled}
            value={userInfoState.givenName}
            onChange={e =>
              setUserInfoState({ ...userInfoState, givenName: e.target.value })
            }
          />
        </label>
        <label className="personal-info-item form-surname">
          <p>
            <img src={nameLogo} alt="" />
            {t("familyName")}
          </p>
          <input
            type="text"
            className="input"
            disabled={isEditingDisabled}
            value={userInfoState.surName}
            onChange={e =>
              setUserInfoState({ ...userInfoState, surName: e.target.value })
            }
          />
        </label>
      </div>
      <label className="personal-info-item">
        <p>
          <img src={addressLogo} alt="" />
          {t("address")}
        </p>
        <input
          type="text"
          className="input"
          disabled={isEditingDisabled}
          value={userInfoState.address}
          onChange={e =>
            setUserInfoState({ ...userInfoState, address: e.target.value })
          }
        />
      </label>

      <div className="two-part-label">
        <label className="personal-info-item first-label">
          <p>
            <img src={city} alt="" /> {t("postcode")}
          </p>
          <input
            type="text"
            className="input postcode-input"
            disabled={isEditingDisabled}
            value={userInfoState.postCode}
            onChange={e =>
              setUserInfoState({ ...userInfoState, postCode: e.target.value })
            }
            size="12"
          />
        </label>
        <label className="personal-info-item city-input">
          <p>
            <img src={city} alt="" /> {t("city")}
          </p>
          <input
            type="text"
            className="input town"
            disabled={isEditingDisabled}
            value={userInfoState.city}
            onChange={e =>
              setUserInfoState({ ...userInfoState, city: e.target.value })
            }
          />
        </label>
      </div>
      <label className="personal-info-item">
        <p>
          <img src={phone} alt="" />
          {t("phone")}
        </p>
        <input
          type="text"
          className="input"
          disabled={isEditingDisabled}
          value={userInfoState.phonenumber}
          onChange={e =>
            setUserInfoState({ ...userInfoState, phonenumber: e.target.value })
          }
        />
      </label>
      <label className="personal-info-item">
        <p>
          <img src={country} alt="" />
          {t("country")}
        </p>
        {!isEditingDisabled && !disableEditMode ? (
          <select
            className="input country-select"
            value={userInfoState.countryRegionCode}
            onBlur={e =>
              setUserInfoState({
                ...userInfoState,
                countryRegionCode: e.target.value
              })
            }
            onChange={e =>
              setUserInfoState({
                ...userInfoState,
                countryRegionCode: e.target.value
              })
            }
          >
            <option hidden disabled value="" />
            {Object.keys(countryCodes).map(key => (
              <option key={key} value={key}>
                {countryCodes[key]}
              </option>
            ))}
          </select>
        ) : (
          <input
            type="text"
            className="input country"
            disabled={true}
            defaultValue={countryCodes[userInfoState.countryRegionCode]}
          />
        )}
      </label>

      <div className="checkbox">
        <label hidden={!isRegistration}>
          <input
            type="checkbox"
            name={"consent"}
            checked={consentState}
            onChange={() => setConsentState(!consentState)}
            hidden={!isRegistration}
          />
          {t("Consent")}
          <a href={t("EulaUrl")} target="_blank" rel="noreferrer">
            {t("LicenseAgreement")}
          </a>
        </label>
      </div>
      <div className="checkbox">
        <label hidden={!isRegistration}>
          <input
            type="checkbox"
            name={"newsletter"}
            checked={newsletterState}
            onChange={() => setNewsletterState(!newsletterState)}
            hidden={!isRegistration}
          />
          {t("Newsletter")}
        </label>
      </div>
      <div className="controls">
        {isRegistration ? (
          <div>
            <input
              type="submit"
              value={t("register")}
              disabled={disableEditMode || !consentState || !isValidated}
              onClick={createUserClicked}
            />
          </div>
        ) : isEditingDisabled ? (
          <input
            type="button"
            value={t("edit")}
            hidden={!isEditingDisabled}
            onClick={() => {
              onUpdateEditingState(!isEditingDisabled);
            }}
            onBlur={() => {
              onUpdateEditingState(!isEditingDisabled);
            }}
          />
        ) : (
          <div className="edit-controls">
            <input
              type="reset"
              value={t("cancel")}
              hidden={isEditingDisabled}
              onClick={() => {
                onUpdateEditingState(!isEditingDisabled);
              }}
            />
            <input
              type="submit"
              value={t("save")}
              hidden={isEditingDisabled}
              onClick={updateUserClicked}
            />
          </div>
        )}
      </div>
    </form>
  );
};
export default PersonalInformationForm;
